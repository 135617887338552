import React from 'react';
import { RichTextSwitch } from 'src/containers';
import { Logos } from 'src/sections';

const CmsLogos = ({ fields, sys }) => {
  const logos = fields?.logos
    ? fields?.logos?.map((logo, i) => {
        return {
          src: 'https://' + logo?.fields?.file?.url,
          alt: logo?.fields?.description || logo?.fields?.title,
          width: logo?.fields?.file?.details?.image?.width / 2,
          height: logo?.fields?.file?.details?.image?.height / 2,
        };
      })
    : [];
  return (
    <Logos
      heading={
        fields?.body ? (
          <RichTextSwitch
            sys={sys}
            content={fields?.body}
            nodes={{
              p: {
                variant: 'h5',
                component: 'h2',
                fieldId: 'body',
              },
            }}
          />
        ) : (
          ''
        )
      }
      logos={logos}
      sx={{ backgroundColor: fields?.backgroundColor, ...fields?.styles }}
      sys={sys}
    />
  );
};

export default CmsLogos;
