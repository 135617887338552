/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';
import {
  PageHeaderCms,
  mvpPageHeaderAdapter,
  PageHeaderWithForm,
  pageHeaderWithFormAdapter,
  ProductPageHeader,
  productsPageHeaderAdapter,
  CareersPageHeader,
  EventHeaderAdapter,
  Breadcrumb,
  partnerDirectoryHeaderAdapter,
  CustomerStoryPageHeader,
  customerStoryPageHeaderAdapter,
  partnerPageHeaderAdapter,
} from 'src/components/headers';
import {
  Footer,
  ItemListSection,
  partnerPageFooterAdapter,
} from 'src/components/PartnerDirectory/PartnerPage';
import { Spacer, spacerAdapter } from 'src/components-v2/Layout';
import { marketoAdapter } from 'src/components/Forms/MarketoForm';
import { MarkdownRenderer } from 'src/components/Blog';
import { FormSection } from 'src/components/Forms';
import {
  AccountTableSection,
  accountTableSectionAdapter,
  IntroSectionCms,
  SocialProofCms,
  mvpSocialProofAdapter,
  ContentBlockSectionCms,
  mvpContentBlockAdapter,
  QuoteCms,
  mvpQuoteAdapter,
  introSectionAdapter,
  SupportingText,
  supportingTextAdapter,
  Grid,
  Column,
  Tile,
  TileGroup,
  tileGroupAdapter,
  Image,
  imageAdapter,
  eventTileGroupAdapter,
} from 'src/components/Layout';
import {
  Showcase,
  mvpShowcaseAdapter,
  blogPostExcerptAdapter,
  productDataAdapter,
  staticDataAdapter,
  interactiveDataAdapter,
  Faq,
  toFaqProps,
  CodeSnippet,
  Paywall,
  usePaywall,
  usePaywallProvider,
} from 'src/components/Surfaces';
import {
  InfoChartSection,
  infoChartSectionAdapter,
  Infographic,
  infographicSectionAdapter,
  Diagram,
  diagramSectionAdapter,
} from 'src/components/CustomerStories';
import {
  FeaturesSection,
  FeaturesSectionItem,
  mvpFeatureSectionAdapter,
} from 'src/components/features/index';
import {
  LandingPageFooter,
  landingPageFooterAdapter,
} from 'src/components/LandingPageFooter';
import FooterExtension from 'src/components/footer-extension';
import {
  CtaRowWithBrushstroke,
  ctaRowWithBrushstrokeAdapter,
} from 'src/components/CtaRowWithBrushstroke';
import { ctaBlockWithButtonsAdapter } from 'src/components/CtaBlockWithButtons';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import {
  CmsComponents,
  Contentful,
  TileTypes,
  FormTypes,
  ContentfulTemplates,
  RESOURCES_PATH,
} from 'src/lib/constants';
import { GatedContent, toGatedContentProps } from 'src/components/GatedContent';
import {
  PartnerCardsSection,
  partnerCardsDiverseAdapter,
} from 'src/components/PartnerDirectory/PartnerCards';
import Cta from 'src/components/cta';
import { formCtaAdapter } from 'src/components/FormCta';
import { Button, Link } from 'src/components/Inputs';
import { VideoEmbed, videoEmbedAdapter } from 'src/components/VideoEmbed';
import DescriptionRow from 'src/components/description-row';
import { descriptionRowAdapter } from 'src/components/DescriptionRow/adapters';
import { RawHtml } from 'src/components/helpers';
import quoteStyles from 'src/components/Layout/Quote/Quote--cms.module.scss';
import articleStyles from 'src/templates/Resources/ArticleTemplate/ArticleTemplate.module.scss';
import blogStyles from './BlogPost.module.scss';
import {
  CmsCtaSwitch,
  CmsEmbeddedVideoCard,
  CmsHeroSwitch,
  CmsLinkCarousel,
  CmsLogos,
  CmsSectionalHeader,
  CmsQuoteSwitch,
  CmsTimeline,
  LeftRightSection,
  LeadForm,
  RichTextHighlightsSection,
} from 'src/sections';
import { CmsSection, CarouselSwitch } from 'src/cms';
import { CmsCardsSwitch } from 'src/sections/Cards/CmsCardsSwitch';
import { CmsSimpleStatCards } from 'src/sections/Cards/CmsSimpleStatCards';
import { SectionAsset } from 'src/components/SectionAsset';

const StaticData = dynamic(
  () => {
    return import('src/components/Surfaces/StaticData/StaticData.jsx');
  },
  {
    ssr: false,
  },
);
const InteractiveData = dynamic(
  () => {
    return import('src/components/Surfaces/InteractiveData/InteractiveData');
  },
  {
    ssr: false,
  },
);

const propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.object,
    }),
  ).isRequired,
  hasAnimations: PropTypes.bool,
};

const ComponentSwitch = ({
  templatePage,
  components,
  styles,
  hasAnimations,
  isGatedContentHeader,
  gatedContentProps,
  setIsFormSubmitted,
  jobLocations,
  jobDepartments,
  filterResults,
  role,
  totalJobCount,
  hasSearchButton,
  events,
  search,
  currentEvent,
  topic,
  handleFormSubmit,
  formType,
  trackingId,
}) => {
  const pageComponents = components.map((component, idx) => {
    switch (_get(component, Contentful.CONTENT_TYPE_ID)) {
      case CmsComponents.ACCORDION:
        try {
          return component.fields.includeFaqSchema ? (
            <Faq {...toFaqProps(component.fields)} key={`faq-${idx}`} />
          ) : null;
        } catch {
          return null;
        }
      case CmsComponents.ACCOUNT_TABLE_SECTION:
        try {
          return (
            <AccountTableSection
              {...accountTableSectionAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.ANCHOR:
        try {
          return (
            <span
              id={component.fields.anchorId}
              key={`anchor-${idx}`}
              data-testid={component.fields.anchorId}
            ></span>
          );
        } catch {
          return null;
        }

      case CmsComponents.BLOG_POST_EXCERPT:
        try {
          return (
            <Showcase
              key={`blog-excerpt-${idx}`}
              className='blogPostExcerpt'
              {...blogPostExcerptAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.BUTTON:
        try {
          const { text, url } = component?.fields;
          return (
            <Button key={`button-${idx}`} href={url}>
              {text}
            </Button>
          );
        } catch {
          return null;
        }
      case CmsComponents.CARDS_SECTION:
        try {
          return <CmsCardsSwitch {...component} key={`cards-section-${idx}`} />;
        } catch {
          return null;
        }
      case CmsComponents.SIMPLE_STATS_SECTION:
        try {
          return (
            <CmsSimpleStatCards
              key={`simple-stats-section-${idx}`}
              {...component}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.CODE_SNIPPET:
        try {
          const { syntax, body } = component?.fields;
          return (
            <CodeSnippet key={`code-snippet-${idx}`} syntax={syntax}>
              {body}
            </CodeSnippet>
          );
        } catch {
          return null;
        }
      case CmsComponents.CAROUSEL:
        try {
          return (
            <CmsLinkCarousel
              {...component?.fields}
              sys={component?.sys}
              key={`link-carousel-${idx}`}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.CONTENT_BLOCK:
        try {
          return (
            <ContentBlockSectionCms
              useThreadsGrid={templatePage === ContentfulTemplates.ARTICLE}
              key={`content-block-${idx}`}
              noPadding={templatePage === ContentfulTemplates.CUSTOMER_STORY}
              {...mvpContentBlockAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.CTA_BANNER:
        try {
          return <CmsCtaSwitch {...component} key={`ctaBanner-${idx}`} />;
        } catch {
          return null;
        }
      case CmsComponents.CTA_BLOCK_WITH_BUTTONS:
        try {
          const adapterData =
            templatePage === ContentfulTemplates.ARTICLE ||
            templatePage === ContentfulTemplates.HUB
              ? { ...ctaBlockWithButtonsAdapter(component.fields) }
              : { ...ctaRowWithBrushstrokeAdapter(component.fields) };
          if (templatePage === ContentfulTemplates.HUB) {
            return (
              <FooterExtension
                key={`footer-extension-${idx}`}
                {...adapterData}
              />
            );
          }
          return (
            <CtaRowWithBrushstroke key={`cta-row-${idx}`} {...adapterData} />
          );
        } catch {
          return null;
        }
      case CmsComponents.CUSTOMER_STORY_PAGE_HEADER:
        try {
          return (
            <CustomerStoryPageHeader
              key={`customer-story-page-header-${idx}`}
              {...customerStoryPageHeaderAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.DESCRIPTION_ROW:
        try {
          return (
            <DescriptionRow
              {...descriptionRowAdapter(component)}
              key={`description-row-${idx}`}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.DIAGRAM_SECTION:
        try {
          return (
            <Diagram
              key={`diagram-section-${idx}`}
              {...diagramSectionAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.EMBEDDED_VIDEO_CARD:
        return (
          <CmsEmbeddedVideoCard
            {...component}
            key={`embedded-video-card-${idx}`}
          />
        );
      case CmsComponents.EVENT_LIST:
        try {
          const { tileGroup, ...tileGroupProps } = eventTileGroupAdapter({
            fields: component.fields,
            events,
            currentEvent,
          });

          if (tileGroup?.length > 0) {
            return (
              <TileGroup {...tileGroupProps} key={`tileGroup-${idx}`}>
                {tileGroup.map((tile, idx) => {
                  return <Tile key={`tile-${idx}`} {...tile} />;
                })}
              </TileGroup>
            );
          }
          return null;
        } catch (e) {
          return null;
        }
      case CmsComponents.FEATURES_SECTION:
        try {
          const defaultAssetSize =
            templatePage === ContentfulTemplates.EVENT ? '240x240' : '112x112';
          const fieldsWithAssetSize = {
            ...component.fields,
            assetSize: component.fields.assetSize ?? defaultAssetSize,
          };
          const {
            title,
            hasBetaItems,
            label,
            hasThreeColumns,
            items,
            ...props
          } = mvpFeatureSectionAdapter(fieldsWithAssetSize);
          return (
            <FeaturesSection
              key={`feature-section-${idx}`}
              {...{
                hasBetaItems,
                label,
                title,
                hasThreeColumns,
              }}
              {...props}
            >
              {items.map((item, idx) => {
                return (
                  <FeaturesSectionItem
                    key={`feature-item-${idx}`}
                    hasThreeColumns={hasThreeColumns}
                    {...item}
                  />
                );
              })}
            </FeaturesSection>
          );
        } catch {
          return null;
        }
      case CmsComponents.FORM_CTA:
        try {
          return (
            <Cta
              key={`cta-${idx}`}
              {...formCtaAdapter({
                fields: component.fields,
                template: templatePage,
              })}
              isBodyForm={templatePage === ContentfulTemplates.ARTICLE}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.ITEMS:
        try {
          return <ItemListSection key={`item-${idx}`} {...component.fields} />;
        } catch {
          return null;
        }
      case CmsComponents.FOOTER:
        try {
          if (templatePage === ContentfulTemplates.PARTNER_PAGE) {
            return (
              <Footer
                key={`partner-footer-${idx}`}
                {...partnerPageFooterAdapter(component.fields)}
              />
            );
          }
          return (
            <LandingPageFooter
              key={`footer-${idx}`}
              {...landingPageFooterAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.GATED_CONTENT:
        try {
          return (
            <GatedContent
              isBodyForm={templatePage === ContentfulTemplates.ARTICLE}
              key={`gated-content-${idx}`}
              gateFields={component.fields.informationGateFormType.fields}
              {...component.fields}
              {...toGatedContentProps(component.fields)}
              setIsFormSubmitted={setIsFormSubmitted}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.GRID_COLUMN:
        return (
          <Column
            key={`column-${idx}`}
            centeredContent={component.fields.centered}
            className={cx(
              styles[component.fields.className],
              styles?.useCaseColumn,
            )}
            small={component.fields.smallColumnWidth}
            medium={component.fields.mediumColumnWidth}
            large={component.fields.largeColumnWidth}
            xlarge={component.fields.extraLargeColumnWidth}
          >
            <CmsComponentSwitch
              isGatedContentHeader={isGatedContentHeader}
              gatedContentProps={gatedContentProps}
              key={`switch-${idx}`}
              {...{ styles, hasAnimations }}
              {...component.fields}
              setIsFormSubmitted={setIsFormSubmitted}
            />
          </Column>
        );
      case CmsComponents.GRID_CONTAINER:
        return (
          <Grid key={`grid-${idx}`} noPadding={!component.fields.padding}>
            <ComponentSwitch
              isGatedContentHeader={isGatedContentHeader}
              gatedContentProps={gatedContentProps}
              key={`switch-${idx}`}
              {...{ styles, hasAnimations }}
              {...component.fields}
              setIsFormSubmitted={setIsFormSubmitted}
            />
          </Grid>
        );
      case CmsComponents.HERO:
        try {
          return <CmsHeroSwitch {...component} key={`hero-${idx}`} />;
        } catch {
          return null;
        }

      case CmsComponents.IMAGE:
        try {
          if (templatePage === ContentfulTemplates.ARTICLE) {
            return (
              <Grid key={`image-${idx}`} useThreadsGrid>
                <Column small={12}>
                  <Image
                    classes={{
                      figureClassName: cx(articleStyles.figure, {
                        [styles.fullWidth]: component.fields.fullWidth,
                      }),
                      imgClassName: articleStyles.image,
                    }}
                    {...imageAdapter(component.fields)}
                  />
                </Column>
              </Grid>
            );
          } else if (templatePage === ContentfulTemplates.BLOG_POST) {
            return (
              <Image
                key={`image-${idx}`}
                classes={{
                  figureClassName: cx(blogStyles.figure, {
                    [blogStyles.fullWidth]: component?.fields?.fullWidth,
                  }),
                  imgClassName: blogStyles.image,
                }}
                {...imageAdapter(component.fields)}
              />
            );
          } else {
            const image = imageAdapter(component.fields);
            return (
              <div
                key={`image-${idx}`}
                className={cx(styles.imageWrapper, {
                  [animationStyles['animated-group__animation']]: hasAnimations,
                  // primary image animations
                  [animationStyles['animated-group__animation--slide-up-40']]:
                    hasAnimations && idx === 0,
                  [animationStyles['animated-group__animation--duration-600']]:
                    hasAnimations && idx === 0,
                  // popup logo
                  [animationStyles[
                    'animated-group__animation--slide-up-bounce'
                  ]]: hasAnimations && idx === 1,
                  [animationStyles['animated-group__animation--delay-600']]:
                    hasAnimations && idx === 1,
                })}
              >
                <div className={styles.imageContainer}>
                  {image?.src && <Image {...image} />}
                </div>
              </div>
            );
          }
        } catch {
          return null;
        }
      case CmsComponents.INFOCHART_SECTION:
        try {
          return (
            <InfoChartSection
              key={`infochart-section-${idx}`}
              {...infoChartSectionAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.INFOGRAPHIC_SECTION:
        try {
          return (
            <Infographic
              key={`infographic-section-${idx}`}
              {...infographicSectionAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      // TODO: Remove interactive & static data once instances are replaced
      case CmsComponents.INTERACTIVE_DATA:
        try {
          return (
            <InteractiveData
              key={`interactive-data-${idx}`}
              {...interactiveDataAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.INTRO_SECTION:
        try {
          return (
            <IntroSectionCms
              key={`intro-section-${idx}`}
              {...introSectionAdapter({
                classes:
                  templatePage === ContentfulTemplates.CUSTOMER_STORY
                    ? 'background background--color-black100'
                    : null,
                ...component.fields,
              })}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.LEAD_FORM:
        try {
          return (
            <LeadForm
              key={`cta-${idx}`}
              {...formCtaAdapter({
                fields: component.fields,
                template: 'Lead Form',
              })}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.LEFT_RIGHT_SECTION:
        return (
          <LeftRightSection {...component} key={`left-right-section-${idx}`} />
        );
      case CmsComponents.LEGACY_CAROUSEL:
        return (
          <CarouselSwitch
            idx={idx}
            component={component}
            templatePage={templatePage}
          />
        );
      case CmsComponents.LOGOS:
        return <CmsLogos {...component} key={`logos-${idx}`} />;
      case CmsComponents.MARKDOWN:
        try {
          return (
            <MarkdownRenderer
              key={`markdown-${idx}`}
              body={component.fields.markdown}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.MARKETO_FORM:
        try {
          const formData = marketoAdapter(component, trackingId);
          return (
            <FormSection
              setIsFormSubmitted={setIsFormSubmitted}
              key={`form-section-${idx}`}
              handleFormSubmit={handleFormSubmit}
              formType={formType}
              formData={{
                ...formData,
                shouldForward: !Boolean(handleFormSubmit),
              }}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PAGE_HEADER:
        try {
          const breadcrumb =
            templatePage === ContentfulTemplates.TOPIC ? (
              <Breadcrumb>
                <Link href={RESOURCES_PATH}>
                  <a>RESOURCES</a>
                </Link>
                {topic}
              </Breadcrumb>
            ) : undefined;

          return templatePage === ContentfulTemplates.CAREERS_PAGE ? (
            <CareersPageHeader
              key={`page-header-${idx}`}
              jobDepartments={jobDepartments}
              jobLocations={jobLocations}
              filterResults={filterResults}
              role={role}
              totalJobCount={totalJobCount}
              hasSearchButton={hasSearchButton}
              {...mvpPageHeaderAdapter(component.fields)}
            />
          ) : (
            <PageHeaderCms
              key={`page-header-${idx}`}
              largeHeading={
                templatePage === ContentfulTemplates.HUB ||
                templatePage === ContentfulTemplates.TOPIC
              }
              breadcrumb={breadcrumb}
              search={
                templatePage === ContentfulTemplates.HUB ||
                templatePage === ContentfulTemplates.TOPIC
                  ? search
                  : null
              }
              {...mvpPageHeaderAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PAGE_HEADER_WITH_FORM:
        try {
          const adapterData =
            templatePage === ContentfulTemplates.EVENT
              ? {
                  ...pageHeaderWithFormAdapter(component.fields),
                  ...EventHeaderAdapter(component, currentEvent),
                }
              : { ...pageHeaderWithFormAdapter(component.fields) };
          return (
            <PageHeaderWithForm
              key={`page-header-with-form-${idx}`}
              {...adapterData}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PARTNER_DIRECTORY_PAGE_HEADER:
        try {
          return (
            <PageHeaderCms
              key={`page-header-${idx}`}
              {...partnerDirectoryHeaderAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PARTNER_PAGE_HEADER:
        try {
          return (
            <PageHeaderCms
              key={`page-header-${idx}`}
              {...partnerPageHeaderAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PARTNERS_LIST:
        try {
          return (
            <PartnerCardsSection
              key={`content-block-${idx}`}
              {...partnerCardsDiverseAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PRODUCT_DATA_SECTION:
        try {
          if (component.fields.type === 'interactive') {
            return (
              <InteractiveData
                key={`product-data-${idx}`}
                {...productDataAdapter(component.fields)}
              />
            );
          }
          return (
            <StaticData
              key={`product-data-${idx}`}
              {...productDataAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.PRODUCTS_PAGE_HEADER:
        try {
          return (
            <ProductPageHeader
              {...productsPageHeaderAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.QUOTE:
        try {
          return <CmsQuoteSwitch {...component} key={`quote-${idx}`} />;
        } catch {
          return null;
        }
      case CmsComponents.QUOTE_CMS:
        try {
          return (
            <QuoteCms
              className={
                templatePage === ContentfulTemplates.ARTICLE
                  ? quoteStyles.quotePlain
                  : ''
              }
              key={`quote-${idx}`}
              {...mvpQuoteAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.RAW_HTML:
        try {
          return <RawHtml key={`raw-html-${idx}`} {...component.fields} />;
        } catch {
          return null;
        }
      case CmsComponents.RICH_TEXT_COMPONENT:
        try {
          if (templatePage === ContentfulTemplates.ARTICLE) {
            return (
              <Grid
                className={articleStyles.richTextContainer}
                key={`rich-text-${idx}`}
                useThreadsGrid
              >
                <Column medium={1} large={2} />
                <Column medium={10} large={8}>
                  <RichTextRenderer
                    classes={{ aClassName: articleStyles.hyperlink }}
                    content={component.fields.body}
                  />
                </Column>
                <Column medium={1} large={2} />
              </Grid>
            );
          }
          return (
            <RichTextRenderer
              key={`rich-text-${idx}`}
              content={component.fields.body}
              className={cx(styles?.richTextContainer, {
                [animationStyles['animated-group__animation']]: hasAnimations,
                [animationStyles['animated-group__animation--slide-up-16']]:
                  hasAnimations,
                [animationStyles['animated-group__animation--duration-300']]:
                  hasAnimations,
              })}
              classes={{
                h1ClassName: styles?.h1ClassName,
                h2ClassName: styles?.h2ClassName,
                h4ClassName: styles?.h4ClassName,
                h6ClassName: styles?.h6ClassName,
                pClassName: styles?.pClassName,
                divClassName:
                  templatePage === ContentfulTemplates.BLOG_POST
                    ? blogStyles.wrapper
                    : styles?.divClassName,
                buttonClassName: styles?.buttonClassName,
                ulClassName: blogStyles.list,
                olClassName: blogStyles.list,
                imgClassName: blogStyles.image,
                figureClassName: cx(blogStyles.figure, {
                  [blogStyles.fullWidth]: component?.fields?.fullWidth,
                }),
                aClassName: blogStyles.hyperlink,
              }}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.RICH_TEXT_HIGHLIGHTS_SECTION:
        try {
          return (
            <RichTextHighlightsSection
              {...component}
              key={`rtHighlightsSection-${idx}`}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.SECTION:
        return <CmsSection {...component} key={`cmsSection-${idx}`} />;
      case CmsComponents.SHOWCASE:
        try {
          return (
            <Showcase
              key={`showcase-${idx}`}
              {...mvpShowcaseAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.SECTIONAL_HEADER:
        return (
          <CmsSectionalHeader {...component} key={`sectional-header-${idx}`} />
        );
      case CmsComponents.SOCIAL_PROOF:
        try {
          return (
            <SocialProofCms
              key={`social-proof-${idx}`}
              {...mvpSocialProofAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.SPACER:
        try {
          return <Spacer key={`spacer-${idx}`} {...spacerAdapter(component)} />;
        } catch {
          return null;
        }
      case CmsComponents.STATIC_DATA:
        try {
          return (
            <StaticData
              key={`static-data-${idx}`}
              {...staticDataAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.SUPPORTING_TEXT:
        try {
          return (
            <SupportingText
              useThreadsGrid={templatePage === ContentfulTemplates.ARTICLE}
              key={`supporting-text-${idx}`}
              {...supportingTextAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.TILE_GROUP:
        try {
          const { tileGroup, ...tileGroupProps } = tileGroupAdapter(
            component.fields,
          );
          return (
            <TileGroup
              {...tileGroupProps}
              key={`tile-group-${idx}`}
              useThreadsGrid={templatePage === ContentfulTemplates.ARTICLE}
            >
              {tileGroup.map((tile, idx) => {
                return (
                  <Tile
                    key={`${
                      tile.type === TileTypes.PEOPLE_SPOTLIGHT
                        ? 'people-spotlight-'
                        : ''
                    }tile-${idx}`}
                    {...tile}
                  />
                );
              })}
            </TileGroup>
          );
        } catch {
          return null;
        }
      case CmsComponents.TIMELINE:
        return <CmsTimeline {...component} key={`timeline-${idx}`} />;
      case CmsComponents.VIDEO_EMBED:
        try {
          return (
            <VideoEmbed
              className={
                templatePage === ContentfulTemplates.ARTICLE
                  ? articleStyles.figure
                  : ''
              }
              isLazy={templatePage === ContentfulTemplates.ARTICLE}
              useThreadsGrid={templatePage === ContentfulTemplates.ARTICLE}
              key={`embedded-video-${idx}`}
              {...videoEmbedAdapter(component.fields)}
            />
          );
        } catch {
          return null;
        }
      case CmsComponents.SECTION_ASSET: {
        try {
          return <SectionAsset {...component} key={`section-asset-${idx}`} />;
        } catch {
          return null;
        }
      }
      default:
        return null;
    }
  });
  return pageComponents;
};

const CmsComponentSwitch = ({
  templatePage = '',
  components = [],
  styles = {},
  hasAnimations = false,
  isGatedContentHeader = false,
  gatedContentProps = {},
  setIsFormSubmitted = null,
  jobLocations = [],
  jobDepartments = [],
  filterResults = null,
  role = '',
  totalJobCount = {},
  hasSearchButton = false,
  events = [],
  search = null,
  currentEvent = {},
  topic = '',
  fields = {},
  handleFormSubmit = () => {
    return null;
  },
  formType = FormTypes.Legacy,
  trackingId = '',
}) => {
  const { hasPaywall, visibleComponents, hiddenComponents, paywallComponent } =
    usePaywall(components);

  const { isContentUnlocked } = usePaywallProvider();

  return (
    <>
      <ComponentSwitch
        templatePage={templatePage}
        components={visibleComponents}
        fields={fields}
        hasPaywall={hasPaywall}
        isContentUnlocked={isContentUnlocked}
        isBelowThePaywall={false}
        handleFormSubmit={handleFormSubmit}
        styles={styles}
        hasAnimations={hasAnimations}
        isGatedContentHeader={isGatedContentHeader}
        gatedContentProps={gatedContentProps}
        setIsFormSubmitted={setIsFormSubmitted}
        jobLocations={jobLocations}
        jobDepartments={jobDepartments}
        filterResults={filterResults}
        role={role}
        totalJobCount={totalJobCount}
        hasSearchButton={hasSearchButton}
        events={events}
        search={search}
        currentEvent={currentEvent}
        topic={topic}
        formType={formType}
        trackingId={trackingId}
      />
      {hasPaywall && (
        <Paywall
          button={{
            buttonText:
              paywallComponent?.fields?.paywallButtonText || 'Read full story',
          }}
          fields={paywallComponent.fields}
          trackingId='CUSTOMER_STORY' // right now customer story is the only page that uses the paywall
        >
          <div className='paywall'>
            <ComponentSwitch
              templatePage={templatePage}
              components={hiddenComponents}
              fields={fields}
              hasPaywall={hasPaywall}
              isContentUnlocked={isContentUnlocked}
              isBelowThePaywall={true}
              handleFormSubmit={handleFormSubmit}
              styles={styles}
              hasAnimations={hasAnimations}
              isGatedContentHeader={isGatedContentHeader}
              gatedContentProps={gatedContentProps}
              setIsFormSubmitted={setIsFormSubmitted}
              jobLocations={jobLocations}
              jobDepartments={jobDepartments}
              filterResults={filterResults}
              role={role}
              totalJobCount={totalJobCount}
              hasSearchButton={hasSearchButton}
              events={events}
              search={search}
              currentEvent={currentEvent}
              topic={topic}
              formType={formType}
              trackingId={trackingId}
            />
          </div>
        </Paywall>
      )}
    </>
  );
};
CmsComponentSwitch.propTypes = propTypes;

export default CmsComponentSwitch;
