import React from 'react';
import { isProduction } from 'src/lib/constants';
import { CmsEditorialHero } from './CmsEditorialHero';
import { CmsLeftRightHero } from './CmsLeftRightHero';
import { CmsLargeAssetLeftHero } from './CmsLargeAssetLeftHero';
import { CmsLargeAssetCenteredHero } from './CmsLargeAssetCenteredHero';
import { CmsFormWithBackgroundHero } from './CmsFormWithBackgroundHero';
import { CmsFormWithAssetHero } from './CmsFormWithAssetHero';

const CmsHeroSwitch = ({ fields, sys }) => {
  const appearance = fields?.appearance;
  switch (appearance) {
    case 'Editorial':
      return <CmsEditorialHero fields={fields} sys={sys} />;
    case 'Left-Right':
      return <CmsLeftRightHero fields={fields} sys={sys} />;
    case 'Large-Asset-Left':
      return <CmsLargeAssetLeftHero fields={fields} sys={sys} />;
    case 'Large-Asset-Centered':
      return <CmsLargeAssetCenteredHero fields={fields} sys={sys} />;
    case 'Form-With-Background':
      return <CmsFormWithBackgroundHero fields={fields} sys={sys} />;
    case 'Form-With-Asset':
      return <CmsFormWithAssetHero fields={fields} sys={sys} />;
    default:
      if (!isProduction) {
        return <p>hmm something went wrong</p>;
      }
      return null;
  }
};

export default CmsHeroSwitch;
