import * as React from 'react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Parallax, Background } from 'react-parallax';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Sys } from 'contentful';
import {
  Box,
  Column,
  Container,
  Section,
  Spacer,
  Row,
} from 'src/components-v2/Layout';

interface Cards {
  content: React.ReactElement;
  sx?: SxProps<Theme>;
}

interface IParallaxCardsProps {
  backgroundAsset: React.ReactElement;
  blur?: number | { min: number; max: number };
  cards: Array<Cards>;
  heading?: React.ReactElement;
  // strength controls how much the background moves.
  // Greater = less bg movement and more foreground movement
  strength?: number;
  sx?: SxProps<Theme>;
  sys?: Sys;
}

const ParallaxCards: React.FunctionComponent<IParallaxCardsProps> = ({
  backgroundAsset,
  blur = 10,
  cards,
  heading,
  strength = 200,
  sx,
  sys,
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  // @ts-ignore
  const backgroundColor = sx?.backgroundColor || sx?.bgColor || 'white';
  return (
    <Section
      sx={{
        '& .background': {
          height: '100vh',
          width: '100vw',
          '& img': {
            mt: { xs: '85px', sm: '150px' },
            mx: 'auto',
            display: 'block',
          },
        },
        '& h2': {
          mb: { xs: '2.1rem', sm: '2.4rem', md: '4.0rem' },
          textAlign: 'center',
        },
        '& h2:last-of-type': {
          mb: '0.8rem',
          textAlign: 'center',
        },
        ...sx,
      }}
      {...sx}
    >
      {heading && heading}
      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Parallax blur={blur} strength={strength}>
          <Background className='background'>{backgroundAsset}</Background>
          <Spacer
            sx={{
              height: { xs: '250px', sm: '32px', lg: '125px' },
            }}
          />
          <Row>
            <Column lgOffset={1} lg={8} smOffset={0} sm={10}>
              <Card
                sx={{
                  backgroundColor: backgroundColor,
                  ...(cards?.[0]?.sx || {}),
                }}
              >
                {cards?.[0]?.content}
              </Card>
            </Column>
            <Column
              lgOffset={15}
              lg={8}
              smOffset={4}
              sm={10}
              sx={{ mt: { lg: '-9%', sm: '5.5%' } }}
            >
              <Card
                sx={{
                  backgroundColor: backgroundColor,
                  ...(cards?.[1]?.sx || {}),
                }}
              >
                {cards?.[1]?.content}
              </Card>
            </Column>
            <Column lgOffset={2} lg={8} smOffset={0} sm={10}>
              <Card
                sx={{
                  backgroundColor: backgroundColor,
                  ...(cards?.[2]?.sx || {}),
                }}
              >
                {cards?.[2]?.content}
              </Card>
            </Column>
            <Column
              lgOffset={14}
              lg={8}
              smOffset={4}
              sm={10}
              sx={{ mt: { lg: '-7.5%', sm: '8%' } }}
            >
              <Card
                sx={{
                  backgroundColor: backgroundColor,
                  ...(cards?.[3]?.sx || {}),
                }}
              >
                {cards?.[3]?.content}
              </Card>
            </Column>
          </Row>
          <Spacer
            sx={{
              height: {
                xs: '80px',
                sm: '170px',
                lg: '125px',
                xl: '300px',
              },
            }}
          />
        </Parallax>
      </Container>
    </Section>
  );
};

export default ParallaxCards;

const Card = ({ children, sx }) => {
  return (
    <Box
      sx={{
        borderRadius: '2.4rem',
        padding: { md: '4.8rem 5.4rem', xs: '2.8rem 3.2rem' },
        border: 'solid 1px',
        borderColor: 'black500',
        '& h3': {
          mb: '1.2rem',
        },
        '& p': {
          mb: 0,
        },
        '& p:nth-last-of-type(1):empty': {
          display: 'none',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
