import React from 'react';
import CardsGrid from 'src/sections/Cards/CardsGrid';
import { CmsSectionalHeader } from 'src/sections';
import { RichTextSwitch } from 'src/containers';
import {
  getHrefFromRichText,
  removeHrefFromRichTextButtonContent,
} from 'src/lib/utils';
import { makeCardGridNodes } from 'src/sections/Cards/CmsCardsSwitch/utils';

const CmsCardsGrid = ({ fields, sys }) => {
  const cards = fields?.gridCardsRow2
    ? fields?.gridCardsRow1?.concat(fields?.gridCardsRow2) || []
    : fields?.gridCardsRow1 || [];
  return (
    <CardsGrid.Container
      sys={sys}
      sx={{
        backgroundColor: fields?.backgroundColor || 'white',
        ...fields?.styles,
      }}
      heading={
        <CmsSectionalHeader
          sys={fields?.header?.sys}
          fields={fields?.header?.fields}
        />
      }
      cards={cards?.map((card, index) => {
        return toGridCard(card, index, fields?.backgroundColor);
      })}
    />
  );
};

export default CmsCardsGrid;

export const toGridCard = (card, index, backgroundColor) => {
  const isFeatured = card?.fields?.appearance === 'Featured';
  const body = isFeatured
    ? card?.fields?.featuredBody
    : card?.fields?.standardBody;
  // because we can't have an <a> tag inside another <a> tag, we must make the tertiary button a button and not a link by removing the href
  const contentWithHrefRemoved = removeHrefFromRichTextButtonContent(
    body?.content,
  );

  return (
    <CardsGrid.Card
      sx={{
        ...card?.fields?.styles,
      }}
      backgroundColor={backgroundColor || 'white'} // not for each card, but for the card section as a whole so we can invert the featured card and set the border color on regular cards
      isFeatured={isFeatured}
      href={getHrefFromRichText(body?.content)}
      key={`cards-grid-card-${index}`}
    >
      <RichTextSwitch
        sys={card?.sys}
        content={{ ...body, content: contentWithHrefRemoved }}
        nodes={makeCardGridNodes(isFeatured)}
      />
    </CardsGrid.Card>
  );
};
