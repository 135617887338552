import React from 'react';
import { LinkCarousel } from 'src/sections/LinkCarousel';
import { CmsSectionalHeader } from 'src/sections';
import { RichTextSwitch } from 'src/containers';
import {
  getHrefFromRichText,
  removeHrefFromRichTextButtonContent,
} from 'src/lib/utils';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';

const CmsLinkCarousel = ({
  header,
  backgroundColor = 'black',
  items = [],
  styles = {},
  sys,
}) => {
  return (
    <LinkCarousel
      sx={{ backgroundColor, ...styles }}
      slides={getSlides(items)}
      heading={<CmsSectionalHeader sys={header?.sys} fields={header?.fields} />}
      sys={sys}
    />
  );
};

export default CmsLinkCarousel;

export const getSlides = (items) => {
  return items.map((item) => {
    const contentWithHrefRemoved = removeHrefFromRichTextButtonContent(
      item?.fields?.body?.content,
    );
    return {
      href: getHrefFromRichText(item?.fields?.body?.content),
      sx: item?.fields?.styles,
      content: (
        <>
          <CmsMediaSwitch
            asset={item?.fields?.asset?.fields}
            width={400}
            height={224}
            queryString={{
              xs: '?fm=webp&w=708&q=60',
              sm: '?fm=webp&w=1120&q=60',
              md: '?fm=webp&w=728&q=60',
              lg: '?fm=webp&w=948&q=60',
            }}
            sys={item?.fields?.asset?.sys}
          />
          <RichTextSwitch
            sys={item?.sys}
            content={{ ...item?.fields?.body, content: contentWithHrefRemoved }}
            nodes={{
              p: {
                variant: 'h5',
                component: 'p',
                fieldId: 'body',
              },
            }}
          />
        </>
      ),
    };
  });
};
