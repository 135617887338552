import React, { FC, useState } from 'react';
import cx from 'classnames';
import { nanoid } from 'nanoid';

import { SelectWithController } from 'src/components/Inputs';

import { gdprFieldsData } from '.';
import { countriesData } from 'src/store/countries';
import { statesData } from 'src/store/states';

import { RenderProps } from '../MarketoForm/MarketoForm';

// helpers
import { getRequiredMessage } from 'src/lib/utils';

interface Props extends RenderProps {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  controlClassName?: string;
  consentCheckboxClassName?: string;
  excludeActiveCountries?: boolean;
  isDarkMode?: boolean;
}

const GdprFields: FC<Props> = ({
  className,
  inputClassName,
  labelClassName,
  controlClassName,
  consentCheckboxClassName,
  control,
  excludeActiveCountries = false,
  errors,
  isDarkMode = false,
  register,
  setValue,
}) => {
  const [userCountry, setUserCountry] = useState({
    requiresConsent: false,
    label: '',
    value: '',
  });
  const [userState, setUserState] = useState({ requiresConsent: false });

  if (excludeActiveCountries) {
    Object.keys(countriesData).forEach((key) => {
      return countriesData[key].isActive && delete countriesData[key];
    });
  }
  const setUserCountryAndConsent = (arg) => {
    setUserCountry(arg);
    setUserState(arg);
  };

  const isConsentCountry = userCountry.requiresConsent === true;
  const isConsentState = userState.requiresConsent;
  const shouldShowStateDropdown = userCountry?.value === 'United States';
  const shouldShowConsentCheckbox = isConsentCountry || isConsentState;
  const optInId = `Marketing_Comms_Opt_in__c-${nanoid()}`;

  return (
    <>
      <SelectWithController
        className={className}
        inputClassName={inputClassName}
        labelClassName={labelClassName}
        controlClassName={controlClassName}
        label={gdprFieldsData.country.label}
        name={gdprFieldsData.country.name}
        describedby={gdprFieldsData.country.describedby}
        isDarkMode={isDarkMode}
        id={gdprFieldsData.country.name}
        register={register}
        required={getRequiredMessage(gdprFieldsData.country.label)}
        errorMessage={errors?.[gdprFieldsData.country.name]?.['message']}
        setValue={setValue}
        control={control}
        options={countriesData}
        onChange={setUserCountryAndConsent}
        autoComplete={gdprFieldsData.country.autoComplete}
      />

      {shouldShowStateDropdown && (
        <SelectWithController
          className={className}
          inputClassName={inputClassName}
          labelClassName={labelClassName}
          controlClassName={controlClassName}
          label={gdprFieldsData.state.label}
          name={gdprFieldsData.state.name}
          describedby={gdprFieldsData.state.describedby}
          isDarkMode={isDarkMode}
          id={gdprFieldsData.state.name}
          register={register}
          required={getRequiredMessage(gdprFieldsData.state.label)}
          errorMessage={errors?.[gdprFieldsData.state.name]?.['message']}
          setValue={setValue}
          control={control}
          options={statesData}
          onChange={setUserState}
          autoComplete={gdprFieldsData.state.autoComplete}
        />
      )}

      <input
        name='Is_Request_in_EEA_or_Unknown__c'
        type='hidden'
        value={isConsentCountry.toString()}
        ref={register}
      />

      {/*
        TODO implement threads checkbox to replace this existing component
        https://jira.plaid.com/browse/SITE-2282
      */}

      {shouldShowConsentCheckbox && (
        <div className={cx('grid-container full', consentCheckboxClassName)}>
          <div className='grid-x form-group'>
            <div className='checkbox__container'>
              <label className='cta-section__label checkbox--label-inline checkbox'>
                <div className='checkbox__input-container'>
                  <input
                    className='checkbox__input'
                    type='checkbox'
                    id={optInId}
                    name='Marketing_Comms_Opt_in__c'
                    ref={register}
                  />
                  <div
                    className={cx('checkbox__faux-input', {
                      'checkbox__faux-input--dark background background--color-black1000':
                        isDarkMode,
                    })}
                  ></div>
                </div>
              </label>
              <label
                htmlFor={optInId}
                className='cta-section__label checkbox__label-text checkbox__label-text--inline-block'
                dangerouslySetInnerHTML={{ __html: gdprFieldsData.optInLabel }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

GdprFields.displayName = 'GdprFields';
export default GdprFields;
