import React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Spacer as SpacerProps } from './Spacer.types';
import { Box } from '../Box';

const Spacer = React.forwardRef(
  ({ height, backgroundColor, sx = {}, sys }: SpacerProps, ref) => {
    const inspectorProps = useContentfulInspectorMode({
      entryId: sys?.id,
    });
    return (
      <Box
        ref={ref}
        sx={{
          height: height
            ? {
                xs: `${Math.ceil(height * 0.38)}px`,
                sm: `${Math.ceil(height * 0.5)}px`,
                md: `${height}px`,
              }
            : 0,
          backgroundColor: backgroundColor
            ? `${backgroundColor}`
            : 'transparent',
          ...sx,
        }}
        data-testid='spacerContainer'
        {...inspectorProps({ fieldId: 'internalTitle' })}
      />
    );
  },
);

export default Spacer;
