import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Wrapper } from 'src/containers';
import { Box, Column, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';

const CmsEditorialHero = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <>
      {(fields?.editorialLeftColumn || fields?.editorialRightColumn) && (
        <Wrapper
          component='header'
          sx={{
            pb: '2rem',
            backgroundColor: bgColor,
            ...fields?.styles,
          }}
          {...inspectorProps({ fieldId: 'internalTitle' })}
        >
          <Column xs={22} xsOffset={1} lg={13} lgOffset={2}>
            <Stack
              sx={{
                justifyContent: 'center',
                height: '100%',
                '& .eyebrow-heading, .decorative-text': {
                  mb: { xs: '2.4rem', lg: ' 3.2rem' },
                },
                '& h2': {
                  mb: { xs: '1.2rem', md: '0' },
                },
                '& p:nth-last-of-type(1):empty': {
                  display: 'none',
                },
              }}
            >
              <RichTextSwitch
                sys={sys}
                content={fields?.editorialLeftColumn}
                nodes={{
                  h2: {
                    variant: 'h1',
                    component: 'h2',
                    fieldId: 'editorialLeftColumn',
                  },
                  h6: {
                    variant: 'h6',
                    component: 'h1',
                    className: 'eyebrow-heading',
                    fieldId: 'editorialLeftColumn',
                  },
                }}
              />
            </Stack>
          </Column>
          <Column lg={1} sx={{ display: { xs: 'none', lg: 'block' } }}>
            <Box
              sx={{
                width: '1px',
                bgcolor: 'black600',
                height: '100%',
                margin: '0 0 0 37%',
              }}
            />
          </Column>
          <Column xs={22} xsOffset={1} lg={8} lgOffset={0} xl={7}>
            <Stack
              sx={{
                justifyContent: 'center',
                height: '100%',
                '& p': {
                  mb: { xs: '2rem', lg: '4rem' },
                },
                '& .button-group': {
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: '1.8rem', sm: '2rem', lg: '2.4rem' },
                },
                '& p:nth-last-of-type(1):empty': {
                  display: 'none',
                },
              }}
            >
              <RichTextSwitch
                sys={sys}
                content={fields?.editorialRightColumn}
                nodes={{
                  p: {
                    variant: 'p',
                    fieldId: 'editorialRightColumn',
                  },
                }}
              />
            </Stack>
          </Column>
        </Wrapper>
      )}
      <CmsMediaSwitch
        asset={{
          ...fields?.asset?.fields,
          isLazy: false,
          styles: {
            width: '100%',
            height: 'auto',
            backgroundColor: bgColor,
            ...fields?.asset?.fields?.styles,
          },
        }}
        queryString={{
          xs: '?fm=webp&w=824&q=60',
          sm: '?fm=webp&w=1280&q=60',
          md: '?fm=webp&w=2048&q=60',
          lg: '?fm=webp&w=3456&q=60',
        }}
        width={1728} // setting a default width in case it was not provided in CMS
        height={700} // setting a default height in case it was not provided in CMS
        sys={fields?.asset?.sys}
      />
    </>
  );
};

export default CmsEditorialHero;
