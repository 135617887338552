import React from 'react';
import { TypographyProps } from './Typography.types';
import { themes } from './TypographyThemes';
import { useIsDarkBackground } from 'src/contexts';

const Typography = React.forwardRef(
  (
    {
      children,
      className = '',
      variant = 'p', // how the element looks
      theme: styleTheme = 'default',
      component, // semantic element
      sx,
      ...props
    }: TypographyProps,
    ref,
  ) => {
    const isDarkBackground = useIsDarkBackground();
    const Component = themes[styleTheme][variant];
    return (
      <Component
        ref={ref}
        as={component} // this is a styled component prop that renders the specified tag
        sx={{
          ...(isDarkBackground && {
            color: 'white',
            '& a': { color: 'white !important' },
          }),
          ...sx,
        }}
        className={className}
        {...props}
      >
        {children}
      </Component>
    );
  },
);

export default Typography;
