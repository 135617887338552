import React, { FC } from 'react';
import { nanoid } from 'nanoid';
import { AccordionItemProps } from './AccordionItem.types';
import { Stack, Box } from 'src/components-v2/Layout';
import { S } from 'src/components-v2/StyledElements/StyledElements';
import AccordionIcons from './AccordionIcons';
import { getBorderStyles, recursiveMap } from './ItemUtils';
import { useIsDarkBackground } from 'src/contexts';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  content,
  icon,
  isActive,
  index,
  activeIndex,
  onClick,
  sys,
  sx,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const accordionId = `accordion-${nanoid()}`;
  const isDarkBackground = useIsDarkBackground();
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <S.Li
      sx={{
        ...getBorderStyles(activeIndex, index, isDarkBackground),
        ...sx,
      }}
      {...inspectorProps({
        fieldId: 'internalTitle',
      })}
    >
      <Box
        onClick={() => {
          return onClick(index);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            return onClick(index);
          }
        }}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        aria-expanded={isActive ? 'true' : 'false'}
        aria-controls={accordionId}
        role='button'
        id={`${accordionId}-control`}
        sx={{
          py: { xs: '2.2rem', sm: '2rem' },
          width: '100%',
          cursor: isActive ? 'initial' : 'pointer',
          '& h4': {
            color: isActive
              ? isDarkBackground
                ? 'white'
                : 'black'
              : isDarkBackground
              ? 'black800'
              : 'black600',
          },
          '& p:nth-last-of-type(1):empty': {
            display: 'none',
          },
          '& .decorative-text': {
            '& svg': {
              mt: { xs: '.3rem', md: '.6rem' },
            },
          },
        }}
        tabIndex={0}
      >
        <Stack direction='row' gap={1.5} alignItems='center'>
          {title}
          <AccordionIcons
            sx={{
              color: isActive
                ? isDarkBackground
                  ? 'white'
                  : 'black'
                : isDarkBackground
                ? 'black800'
                : 'black600',
            }}
            icon={icon}
            isActive={isActive}
          />
        </Stack>
      </Box>
      <Box
        aria-labelledby={`${accordionId}-control`}
        id={accordionId}
        role='region'
        sx={{
          display: 'grid',
          gridTemplateRows: isActive ? '1fr' : '0fr',
          transition: 'grid-template-rows 0.4s cubic-bezier(0.2, 1.2, 0.32, 1)',
          willChange: isHovered ? 'grid-template-rows' : 'auto',
        }}
      >
        <Box
          sx={{
            overflowY: 'hidden',
          }}
        >
          {recursiveMap(content, (child) => {
            if (child?.type === 'a') {
              return React.cloneElement(child, {
                ...child.props,
                tabIndex: isActive ? 0 : -1,
              });
            }
            return child;
          })}
        </Box>
      </Box>
    </S.Li>
  );
};

export default AccordionItem;
