import React from 'react';
import { Icon } from 'src/components-v2/DataDisplay';
import { S } from 'src/components-v2/StyledElements/StyledElements';
import { Stack } from 'src/components-v2/Layout';
import { useIsDarkBackground } from 'src/contexts';

interface ILinkCarouselBtns {
  clickCount: number;
  previousCard: () => void;
  nextCard: () => void;
  maxCount: number;
}

const getButtonStyles = (isDark) => {
  return {
    border: 'solid 1px',
    borderRadius: '240px',
    height: '48px',
    width: '100px',
    '&:hover': {
      backgroundColor: isDark ? 'white' : 'black',
      color: isDark ? 'black' : 'white',
    },
    '&:active': {
      backgroundColor: isDark ? 'black600' : 'black500',
      color: isDark ? 'black' : 'white',
    },
  };
};

const LinkCarouselBtns: React.FC<ILinkCarouselBtns> = ({
  clickCount = 0,
  previousCard,
  nextCard,
  maxCount,
}) => {
  const isDarkBackground = useIsDarkBackground();
  const inUseColor = isDarkBackground ? 'white' : 'black800';
  const notInUseColor = isDarkBackground ? 'black800' : 'black400';
  return (
    <Stack direction='row'>
      <S.Button
        sx={{
          ...getButtonStyles(isDarkBackground),
          color: clickCount === 0 ? notInUseColor : inUseColor,
          mr: '1rem',
        }}
        onClick={previousCard}
        disabled={clickCount === 0}
        aria-label='previous slide'
      >
        <Icon
          sx={{
            height: '45px',
            width: '45px',
            margin: '0.15rem 2.5rem',
            color: 'inherit',
          }}
          icon='ArrowLeft'
        />
      </S.Button>
      <S.Button
        sx={{
          ...getButtonStyles(isDarkBackground),
          color: clickCount >= maxCount ? notInUseColor : inUseColor,
        }}
        disabled={clickCount >= maxCount}
        onClick={nextCard}
        aria-label='next slide'
      >
        <Icon
          sx={{
            height: '45px',
            width: '45px',
            margin: '0.15rem 2.5rem',
            color: 'inherit',
          }}
          icon='ArrowRight'
        />
      </S.Button>
    </Stack>
  );
};

export default LinkCarouselBtns;
