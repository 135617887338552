import React from 'react';
import { isProduction } from 'src/lib/constants';
import { CmsCardsGrid } from '../CardsGrid/CMSCardsGrid';
import { CmsSectionalHeader } from 'src/sections/Header';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { ParallaxCards } from '../ParallaxCards';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import { Paginator } from '../Paginator';
import {
  makeImageCardNodes,
  makeStatCardNodes,
  parallaxCardNodes,
} from './utils';
import { LinkedCardsSection } from 'src/sections/LinkedCardsSection';
import { FilteredCardsSection } from 'src/sections/FilteredCardsSection';

const CmsCardsSwitch = ({ fields, sys }) => {
  switch (fields?.appearance) {
    case 'Image cards':
      return (
        <Paginator
          appearance='image'
          sys={sys}
          sx={{
            backgroundColor: fields?.backgroundColor || 'white',
            ...fields?.styles,
          }}
          heading={
            fields?.header ? (
              <CmsSectionalHeader
                sys={fields?.header?.sys}
                fields={fields?.header?.fields}
              />
            ) : null
          }
          cards={fields?.imageCards?.map((card, index) => {
            const sys = card?.sys;
            return {
              content: (
                <RichTextSwitch
                  key={`image-card-${index}`}
                  sys={sys}
                  content={card?.fields?.body}
                  nodes={makeImageCardNodes(fields)}
                />
              ),
              sx: card?.fields?.styles,
            };
          })}
        />
      );

    case 'Grid cards':
      return <CmsCardsGrid fields={fields} sys={sys} />;

    case 'Stat cards':
      return (
        <Paginator
          appearance='stat'
          sys={sys}
          sx={{
            backgroundColor: fields?.backgroundColor || 'white',
            ...fields?.styles,
          }}
          cards={fields?.statCards?.map((card, index) => {
            const sys = card?.sys;
            return {
              content: (
                <RichTextSwitch
                  key={`stat-card-${index}`}
                  sys={sys}
                  content={card?.fields?.body}
                  nodes={makeStatCardNodes(sys)}
                />
              ),
              sx: card?.fields?.styles,
            };
          })}
        />
      );

    case 'Parallax cards':
      return (
        <ParallaxCards
          sys={sys}
          heading={
            <CmsSectionalHeader
              sys={fields?.header?.sys}
              fields={fields?.header?.fields}
            />
          }
          sx={{
            backgroundColor: fields?.backgroundColor || 'white',
            ...fields?.styles,
          }}
          backgroundAsset={
            <CmsMediaSwitch
              asset={fields?.parallaxAsset?.fields}
              queryString={{
                xs: '?fm=webp&w=760&q=60',
                sm: '?fm=webp&w=1280&q=60',
                md: '?fm=webp&w=2046&q=60',
                lg: '?fm=webp&w=2938&q=60',
              }}
              width={1469}
              height={826}
            />
          }
          cards={fields?.parallaxCards?.map((card, index) => {
            return {
              content: (
                <RichTextSwitch
                  key={`parallax-card-${index}`}
                  sys={card?.sys}
                  content={card?.fields?.body}
                  nodes={parallaxCardNodes}
                />
              ),
              sx: { ...card?.fields?.styles },
            };
          })}
        />
      );

    case 'Linked cards':
      return <LinkedCardsSection fields={fields} sys={sys} />;

    case 'Filtered cards':
      return <FilteredCardsSection fields={fields} sys={sys} />;

    default:
      if (!isProduction) {
        return <p>oops...something went wrong!</p>;
      } else {
        return null;
      }
  }
};

export default CmsCardsSwitch;
