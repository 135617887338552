import React from 'react';
import { ContainerProps } from './Container.types';
import styled from 'threads5/styles/styled';

const StyledDiv = styled('div')({});

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className = '', sx, ...props }, ref) => {
    return (
      <StyledDiv
        ref={ref}
        className={className}
        {...props}
        sx={{
          margin: '0 auto',
          maxWidth: '1730px',
          px: {
            xs: '1.3rem',
            sm: '4rem',
          },
          py: 0,
          ...sx,
        }}
      >
        {children}
      </StyledDiv>
    );
  },
);

Container.displayName = 'Container';

export default Container;
